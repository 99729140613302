import React from "react";
import useTodo, { Todo } from "../useTodo";
import Table from "react-bootstrap/Table";
import FormCheck from "react-bootstrap/FormCheck";
import { getPriorityName } from "../constants";

export default function LatestList() {
  const { latestTodos, editTodo } = useTodo();

  const handleTodoStatusChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    todo: Todo
  ) => {
    const { id, isCompleted, ...others } = todo;
    if (id) {
      editTodo(id, { isCompleted: e.target.checked, id, ...others });
    }
  };

  return (
    <div>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Status</th>
            <th>Priority</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {latestTodos.map((t) => (
            <tr key={t.id}>
              <td>
                <FormCheck
                  type="checkbox"
                  id={t.id}
                  checked={t.isCompleted}
                  onChange={(e) => handleTodoStatusChange(e, t)}
                />
              </td>
              <td>{getPriorityName(t.priority)}</td>
              <td>{t.description}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
