import React from "react";
import { Navigate } from "react-router-dom";
import useAuth from "../useAuth";

export default function AuthenticatedPage({ children }: any) {
  const { user } = useAuth();

  if (!user?.isLoggedIn) return <Navigate to="/" />;

  return <div>{children}</div>;
}
