import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { localStorageConstant } from "./constants";

interface User {
  username?: String;
  isLoggedIn: boolean;
}

interface AuthContextType {
  user?: User;
  loading: boolean;
  error?: any;
  login: (email: string, password: string) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

// Export the provider as we need to wrap the entire app with it
export function AuthProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [user, setUser] = useState<User>(() => {
    // getting stored value
    const saved = localStorage.getItem(localStorageConstant.userKey);
    return saved ? JSON.parse(saved) : { isLoggedIn: false };
  });
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);

  let navigate = useNavigate();
  const location = useLocation();

  // If we change page, reset the error state.
  useEffect(() => {
    if (error) setError(null);
  }, [error, location.pathname]);

  useEffect(() => {
    if (user) {
      localStorage.setItem(localStorageConstant.userKey, JSON.stringify(user));
    }
  }, [user]);

  useEffect(() => {
    const saved = localStorage.getItem(localStorageConstant.userKey);
    if (saved) {
      setUser(JSON.parse(saved));
    }
    setLoadingInitial(false);
  }, []);

  const memoedValue = useMemo(() => {
    function login(username: string, password: string) {
      setLoading(true);
      if (password === "maybank" && username === "maybank") {
        setUser({ username: "maybank", isLoggedIn: true });
        navigate("dashboard");
      } else {
        // failed login
        setUser({ isLoggedIn: false });
        // setError(error)
      }
      setLoading(false);
    }

    function logout() {
      localStorage.removeItem(localStorageConstant.userKey);
      setUser({ isLoggedIn: false });
    }

    return {
      user,
      loading,
      error,
      login,
      logout,
    };
  }, [user, loading, error, navigate]);

  // We only want to render the underlying app after we
  // assert for the presence of a current user.
  return (
    <AuthContext.Provider value={memoedValue}>
      {!loadingInitial && children}
    </AuthContext.Provider>
  );
}

export default function useAuth() {
  return useContext(AuthContext);
}
