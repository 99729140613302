import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import useTodo from "../useTodo";
import { getPriorityName } from "../constants";

interface NewItemModalProps {
  isOpenModal: boolean;
  handleClose: () => void;
}

export default function NewItemModal({
  isOpenModal,
  handleClose,
}: NewItemModalProps) {
  const [status, setStatus] = React.useState("");
  const [priority, setPriority] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [date, setDate] = React.useState("");

  const { addTodo } = useTodo();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // const form = event.currentTarget;
    // if (form.checkValidity() === false) {
    //   event.preventDefault();
    //   event.stopPropagation();
    // }
    if (
      status.length !== 0 &&
      priority.length !== 0 &&
      description.length !== 0 &&
      date.length !== 0
    ) {
      const isCompleted = status === "completed" ? true : false;
      addTodo({
        isCompleted,
        priority,
        description,
        date: new Date(date).toISOString(),
      });
      handleClose();
    }
  };

  return (
    <div>
      <Modal show={isOpenModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="status">
              <Form.Select
                aria-label="Select status"
                onChange={(e) => setStatus(e.target.value)}
              >
                <option>Select Status</option>
                <option value="backlog">Backlog</option>
                <option value="completed">Completed</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="priority">
              <Form.Select
                aria-label="Select Priority"
                onChange={(e) => setPriority(e.target.value)}
              >
                <option>Select Priority</option>
                <option value="2">{getPriorityName("2")}</option>
                <option value="1">{getPriorityName("1")}</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="description">
              <Form.Control
                type="text"
                placeholder="Description"
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="date">
              <Form.Label>Select Date</Form.Label>
              <Form.Control
                type="date"
                placeholder="Date"
                onChange={(e) => setDate(e.target.value)}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Add New
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
