import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { localStorageConstant } from "./constants";
import { nanoid } from "nanoid";

export interface Todo {
  isCompleted: boolean;
  priority: string;
  description: string;
  date: string;
  id?: string;
}

interface TodoContextType {
  todos?: Todo[];
  latestTodos: Todo[];
  totalCompletedTodos: number;
  addTodo: (todo: Todo) => void;
  deleteTodo: (id: string) => void;
  editTodo: (id: string, todo: Todo) => void;
  filterTodos: (filter: string) => Todo[];
}

const TodoContext = createContext<TodoContextType>({} as TodoContextType);

export function TodoProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [todos, setTodos] = useState<Todo[]>(() => {
    // getting stored value
    const saved = localStorage.getItem(localStorageConstant.todosKey);
    return saved ? JSON.parse(saved) : [];
  });

  const [latestTodos, setLatestTodos] = useState<Todo[]>([]);

  const [totalCompletedTodos, setTotalCompletedTodos] = useState<number>(0);

  useEffect(() => {
    if (todos) {
      localStorage.setItem(
        localStorageConstant.todosKey,
        JSON.stringify(todos)
      );

      const tempArray: Todo[] = JSON.parse(JSON.stringify(todos));

      tempArray.sort((a, b) => {
        if (a.date > b.date) {
          return -1;
        }
        if (a.date < b.date) {
          return 1;
        }
        return 0;
      });

      setLatestTodos(
        tempArray
          .filter((v) => String(v.date) > new Date().toISOString())
          .slice(0, 4)
      );
      const totalCompleted = tempArray.filter((v) => v.isCompleted);
      setTotalCompletedTodos(totalCompleted.length);
    }
  }, [todos]);

  // useEffect(() => {
  //   const saved = localStorage.getItem(localStorageConstant.userKey);
  //   if (saved) {
  //     setUser(JSON.parse(saved));
  //   }
  //   setLoadingInitial(false);
  // }, []);

  const memoedValue = useMemo(() => {
    function addTodo(todo: Todo) {
      setTodos(todos.concat([{ id: nanoid(), ...todo }]));
    }
    function deleteTodo(id: string) {
      const newTodos = todos.filter((t) => t.id !== id);
      setTodos(newTodos);
    }
    function editTodo(id: string, todo: Todo) {
      const newTodos = todos.filter((t) => t.id !== id);
      newTodos.push({ id, ...todo });
      setTodos(newTodos);
    }
    function filterTodos(filter: string) {
      // https://replit.com/@amirulabu/LoneFlimsyButtons#index.js
      const regex = new RegExp(filter, "g");
      const newTodos = todos.filter((t) => t.description.match(regex));
      return newTodos;
    }

    return {
      addTodo,
      deleteTodo,
      editTodo,
      filterTodos,
      todos,
      latestTodos,
      totalCompletedTodos,
    };
  }, [todos, latestTodos, totalCompletedTodos]);

  return (
    <TodoContext.Provider value={memoedValue}>{children}</TodoContext.Provider>
  );
}

export default function useTodo() {
  return useContext(TodoContext);
}
