import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import useTodo from "../useTodo";
import { PieChart, Pie, Cell, Legend } from "recharts";

export default function Review() {
  const { totalCompletedTodos, todos } = useTodo();

  const getTotalTodos = () => todos?.length ?? 0;

  const getTotalNotCompletedTodos = () => getTotalTodos() - totalCompletedTodos;
  const getData = () => [
    { name: "Completed", value: totalCompletedTodos },
    { name: "Not Completed", value: getTotalNotCompletedTodos() },
  ];

  const colors = ["#a4de6c", "#83a6ed"];

  return (
    <Container>
      <Row>
        <Col lg={true}>
          <div className="d-flex flex-column justify-content-center align-items-center h-100">
            <div className="border">
              <div className="p-5">
                <span className="fs-1">
                  {totalCompletedTodos} / {getTotalTodos()}
                </span>
              </div>
            </div>
            <div>
              <p>Number of Task vs Completed Task</p>
            </div>
          </div>
        </Col>
        <Col lg={true}>
          <div className="d-flex flex-column justify-content-center align-items-center h-100">
            <div>
              <PieChart width={730} height={250}>
                <Pie
                  data={getData()}
                  dataKey="value"
                  nameKey="name"
                  innerRadius={0}
                  outerRadius={80}
                >
                  {getData().map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index]} />
                  ))}
                </Pie>
                <Legend verticalAlign="bottom" height={36} />
              </PieChart>
            </div>
            <div>
              <p>Number of Task vs Completed Task</p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
