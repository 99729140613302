import React from "react";
import Alert from "react-bootstrap/Alert";

interface RedAlertProps {
  title: string;
  content: string;
}

export default function RedAlert(props: RedAlertProps) {
  return (
    <div>
      <Alert variant="danger">
        <Alert.Heading>{props.title}</Alert.Heading>
        <p>{props.content}</p>
      </Alert>
    </div>
  );
}
