import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import { Link } from "react-router-dom";
import useAuth from "../useAuth";
import AuthenticatedPage from "../components/AuthenticatedPage";
import NewItemModal from "../components/NewItemModal";
import LatestList from "../components/LatestList";
import Review from "../components/Review";
import AllList from "../components/AllList";

export default function Dashboard() {
  const { logout, user } = useAuth();
  const [isOpenModal, setIsOpenModal] = React.useState(false);

  const handleClose = () => setIsOpenModal(false);
  const handleShow = () => setIsOpenModal(true);

  return (
    <AuthenticatedPage>
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container fluid>
          <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/dashboard">
                Dashboard
              </Nav.Link>
            </Nav>
            {user?.username ? (
              <>
                <Badge bg="secondary">{user?.username}</Badge>
                <Nav.Link as={Link} to="/" onClick={() => logout()}>
                  Logout
                </Nav.Link>
              </>
            ) : (
              <Nav.Link as={Link} to="/">
                Login
              </Nav.Link>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className="pt-5">
        <Tabs defaultActiveKey="latest" className="mb-3">
          <Tab eventKey="latest" title="Latest List">
            <LatestList />
          </Tab>
          <Tab eventKey="all" title="All List">
            <AllList />
          </Tab>
          <Tab eventKey="review" title="Review">
            <Review />
          </Tab>
        </Tabs>
      </Container>

      {isOpenModal ? (
        <NewItemModal isOpenModal={isOpenModal} handleClose={handleClose} />
      ) : (
        <div className="position-absolute bottom-0 end-0">
          <div className="p-3" style={{ fontSize: "30px" }}>
            <Button variant="outline-primary" onClick={handleShow}>
              + Add new todo
            </Button>
          </div>
        </div>
      )}
    </AuthenticatedPage>
  );
}
