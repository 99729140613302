import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import RedAlert from "../components/RedAlert";
import useAuth from "../useAuth";

export default function Login() {
  const [validated, setValidated] = React.useState(false);
  const [touched, setTouched] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const { login } = useAuth();

  const checkCredentials = () => {
    if (password === "maybank" && username === "maybank") {
      setValidated(true);
    } else {
      setValidated(false);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setTouched(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    checkCredentials();
    login(username, password);
  };

  const showWarningAlert = () => {
    if (validated) {
      return null;
    }
    return (
      <RedAlert
        title={"Username or Password error"}
        content={"You got the wrong username or password, please try again"}
      />
    );
  };

  return (
    <Container fluid>
      <Row>
        <Col lg={true} xs={4} className="mx-auto">
          <div>
            <img
              src="https://placekitten.com/1000/1000"
              alt="kitten"
              width={"100%"}
            />
          </div>
        </Col>
        <Col
          lg={true}
          className="d-flex align-items-center justify-content-center"
        >
          <div className="w-100 px-5">
            <div className="pt-5">{touched && showWarningAlert()}</div>
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicUsername">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter username"
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Login
              </Button>
              <div className="pt-5">
                <p className="text-muted">Username and password is: maybank</p>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
