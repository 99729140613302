import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import useTodo, { Todo } from "../useTodo";
import { getPriorityName } from "../constants";

interface ItemModalProps {
  isOpenModal: boolean;
  handleClose: () => void;
  todo?: Todo;
}

export default function ItemModal({
  isOpenModal,
  handleClose,
  todo,
}: ItemModalProps) {
  const [status, setStatus] = React.useState(
    todo?.isCompleted ? "completed" : "backlog"
  );
  const [priority, setPriority] = React.useState(todo?.priority ?? "");
  const [description, setDescription] = React.useState(todo?.description ?? "");
  const [date, setDate] = React.useState(String(todo?.date) ?? "");

  const { editTodo } = useTodo();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (
      status.length !== 0 &&
      priority.length !== 0 &&
      description.length !== 0 &&
      date?.length !== 0
    ) {
      const isCompleted = status === "completed" ? true : false;
      if (todo?.id) {
        editTodo(todo.id, {
          isCompleted,
          priority,
          description,
          date: new Date(date).toISOString(),
        });
      }
      handleClose();
    }
  };

  const getDateFormatForInput = (d: string) => {
    console.log(d);
    return d.slice(0, 10);
  };

  return (
    <div>
      <Modal show={isOpenModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="status">
              <Form.Select
                aria-label="Select status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option>Select Status</option>
                <option value="backlog">Backlog</option>
                <option value="completed">Completed</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="priority">
              <Form.Select
                aria-label="Select Priority"
                value={priority}
                onChange={(e) => setPriority(e.target.value)}
              >
                <option>Select Priority</option>
                <option value="2">{getPriorityName("2")}</option>
                <option value="1">{getPriorityName("1")}</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="description">
              <Form.Control
                type="text"
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="date">
              <Form.Label>Select Date</Form.Label>
              <Form.Control
                type="date"
                placeholder="Date"
                value={getDateFormatForInput(String(date)) ?? ""}
                onChange={(e) => setDate(e.target.value)}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
