import React from "react";
import useTodo, { Todo } from "../useTodo";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { getPriorityName } from "../constants";
import ItemModal from "./ItemModal";
import DeleteModal from "./DeleteModal";

export default function AllList() {
  const { todos } = useTodo();

  const [currentTodo, setCurrentTodo] = React.useState<Todo>();

  const [isOpenEditModal, setIsOpenEditModal] = React.useState(false);

  const [isOpenDeleteModal, setIsOpenDeleteModal] = React.useState(false);

  const presentableDate = (date: any) => {
    return new Intl.DateTimeFormat("en-GB", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    }).format(new Date(date));
  };

  const handleDelete = (t: Todo) => {
    setCurrentTodo(t);
    setIsOpenDeleteModal(true);
  };

  const handleEdit = (t: Todo) => {
    setCurrentTodo(t);
    setIsOpenEditModal(true);
  };

  return (
    <div>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Status</th>
            <th>Priority</th>
            <th>Description</th>
            <th>Date</th>
            <th>Edit or Delete</th>
          </tr>
        </thead>
        <tbody>
          {todos?.map((t) => (
            <tr key={t.id}>
              <td>{t.isCompleted ? "Completed" : "Not Completed"}</td>
              <td>{getPriorityName(t.priority)}</td>
              <td>{t.description}</td>
              <td>{presentableDate(t.date)}</td>
              <td>
                <Button
                  variant="secondary"
                  className="me-3"
                  onClick={() => handleEdit(t)}
                >
                  Edit
                </Button>
                <Button variant="danger" onClick={() => handleDelete(t)}>
                  X
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {isOpenEditModal ? (
        <ItemModal
          isOpenModal={isOpenEditModal}
          handleClose={() => setIsOpenEditModal(false)}
          todo={currentTodo}
        />
      ) : null}

      {isOpenDeleteModal ? (
        <DeleteModal
          isOpenModal={isOpenDeleteModal}
          handleClose={() => setIsOpenDeleteModal(false)}
          todo={currentTodo}
        />
      ) : null}
    </div>
  );
}
