export const localStorageConstant = {
  userKey: "user",
  todosKey: "todo",
};

export const getPriorityName = (p: string) => {
  switch (p) {
    case "1":
      return "Low Priority";
    case "2":
      return "High Priority";
    default:
      return "Low Priority";
  }
};
