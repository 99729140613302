import React from "react";
import useTodo, { Todo } from "../useTodo";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

interface DeleteModalProps {
  isOpenModal: boolean;
  handleClose: () => void;
  todo?: Todo;
}

export default function DeleteModal({
  isOpenModal,
  handleClose,
  todo,
}: DeleteModalProps) {
  const { deleteTodo } = useTodo();

  return (
    <div>
      <Modal show={isOpenModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete task - {todo?.description} ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button
            variant="secondary"
            className="me-3"
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              if (todo?.id) {
                deleteTodo(todo.id);
              }
              handleClose();
            }}
          >
            Yes
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
}
